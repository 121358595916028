import { createSlice } from '@reduxjs/toolkit'

export const openDepositSlice = createSlice({
    name: 'openDeposit',
    initialState: {
      value: false,
    },
    reducers: {
        setOpenDeposit: (state, action) => {
        state.value = action.payload
      },
    },
})
 
// Action creators are generated for each case reducer function
export const { setOpenDeposit } = openDepositSlice.actions;

export default openDepositSlice.reducer;


