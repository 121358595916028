import { useState} from "react";
import { Button } from "@material-tailwind/react";
import axios from 'axios';

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';


axios.defaults.withCredentials = true;



const StripeForm = () => {

    const stripe = useStripe();
    const elements = useElements();


    const clientSiteUrl = JSON.parse(localStorage.getItem('clientSiteUrl'));

    const [errorMessage, setErrorMessage] = useState(null);
    const [stripeFormProcessing, setStripeFormProcessing] = useState(false);

    const handleSubmit = async (event) => {
        setStripeFormProcessing(() => true);

        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: clientSiteUrl,
            },
        });


        if (error) {
            setStripeFormProcessing(() => false);

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            setStripeFormProcessing(() => false);

            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };




    return (

        <form onSubmit={handleSubmit}>
            {errorMessage && <div>{errorMessage}</div>}

            <PaymentElement />


            {stripeFormProcessing === true &&
                <Button disabled={!stripe || stripeFormProcessing ? true : false} className="my-2 text-xl" color='amber' variant="gradient" fullWidth>
                    <span className="flex items-center justify-center gap-2 mx-auto">
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                    </span>
                </Button>
            }
            {stripeFormProcessing === false &&
                <Button onClick={handleSubmit} disabled={!stripe} className="my-2 text-xl" color='amber' variant="gradient" fullWidth>
                    Submit
                </Button>
            }

        </form>

    )

}


export default StripeForm