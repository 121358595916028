import { createSlice } from '@reduxjs/toolkit'

export const openMpesaPaymentSlice = createSlice({
    name: 'openMpesaPayment',
    initialState: {
      value: false,
    },
    reducers: {
        setOpenMpesaPayment: (state, action) => {
        state.value = action.payload
      },
    },
})
 
// Action creators are generated for each case reducer function
export const { setOpenMpesaPayment } = openMpesaPaymentSlice.actions;

export default openMpesaPaymentSlice.reducer;


