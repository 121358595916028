
import { useRef, useState, useEffect } from "react";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import StripePayment from './StripePayment';
import StripePaymentStatus from './StripePaymentStatus';

const StripeElements = (props) => {
  const stripePublishableKey = JSON.parse(localStorage.getItem('stripePublishableKey'));


    const [stripePromise] = useState(() => loadStripe(stripePublishableKey))


    useEffect(() => {

        const clientSecretV = new URLSearchParams(window.location.search).get(
          'payment_intent_client_secret'
        );
        if (clientSecretV) {
          props.setStripeProcessPayment(true);
    
        }
      }, []);
    


    return (
        <Elements stripe={stripePromise} className="text-gray-200">
            <StripePaymentStatus
                                  {...props}


            />
        </Elements>

    )
}

export default StripeElements
