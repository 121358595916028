import { useRef, useState, useEffect } from "react";
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Context } from './Constants';
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Typography } from "@material-tailwind/react";
import axios from 'axios';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import ReactDOM from 'react-dom';


import StripeForm from './StripeForm';
import StripePaymentStatus from './StripePaymentStatus';
import { toast } from "react-toastify";

axios.defaults.withCredentials = true;

 

const StripePayment = (props) => {

    //   const stripePromise = loadStripe(stripePublishableKey);

    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const dispatch = useDispatch();

    const accountBalance = useSelector((state) => state.accountBalance.value);
    const authentication = useSelector((state) => state.authentication.value);
    const previousGames = useSelector((state) => state.previousGames.value);
    const userId = useSelector((state) => state.userId.value);
    const myBets = useSelector((state) => state.myBets.value);
    const username = useSelector((state) => state.username.value);
    const phone = useSelector((state) => state.phone.value);
    const email = useSelector((state) => state.email.value);

    const transactionAmountRef = useRef();
    const errRef = useRef();

    const [errMsg, setErrMsg] = useState([]);

    const [transactionAmount, setTransactionAmount] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [validTransactionAmount, setValidTransactionAmount] = useState(false);
    const [transactionAmountFocus, setTransactionAmountFocus] = useState(false);

    const [stripeInitiatePayment, setStripeInitiatePayment] = useState(true);


    // const [options, setOptions] = useState(null);
    const [stripePromise, setStripePromise] = useState(() => loadStripe(stripePublishableKey))

    const [errorMessage, setErrorMessage] = useState(null);
    const minimumDeposit = JSON.parse(localStorage.getItem('minimumDeposit'));
    const maximumDeposit = JSON.parse(localStorage.getItem('maximumDeposit'));
    const marketerStatus = JSON.parse(localStorage.getItem('marketerStatus'));
    const directDeposit = JSON.parse(localStorage.getItem('directDeposit'));
    const directDepositInstructions = JSON.parse(localStorage.getItem('directDepositInstructions'));
    const withdrawalInstructions = JSON.parse(localStorage.getItem('withdrawalInstructions'));
    const stripePublishableKey = JSON.parse(localStorage.getItem('stripePublishableKey'));
    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));


  
    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
      }

    const options = {
        // passing the client secret obtained in step 3
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        appearance: {
            theme: 'stripe',

            variables: {
                colorPrimary: '#0570de',
                colorBackground: '#ffffff',
                colorText: '#30313d',
                colorDanger: '#df1b41',
                fontFamily: 'Ideal Sans, system-ui, sans-serif',
                spacingUnit: '2px',
                borderRadius: '4px',
                // See all possible variables below
            }
        },
    };


  

    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }



    useEffect(() => {
        if (transactionAmount >= 1) {
            setValidTransactionAmount(true);
        } else {
            setValidTransactionAmount(false);
        }
    }, [transactionAmount])



    const handleInitiatePayment = async (e) => {
        e.preventDefault();
        try {
            props.setStripePaymentProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/initiateStripePayment`,
                { userId: userId, amount: transactionAmount },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            setClientSecret(() => response?.data.client_secret);
            setStripeInitiatePayment(() => false);
            props.setStripePaymentProcessing(() => false);

        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
            } else {
                errorNotify(err.response.data.message);
                setErrMsg(err.response.data.message);
                errRef.current.scrollIntoView();
            }
            props.setStripePaymentProcessing(() => false);
        }
    }


    return (
        <div>
            <div className="grid grid-cols-2 p-4 gap-2 text-sm w-full text-orange-200 border rounded">
                <span className="w-full">
                    Minimum Deposit: <b>{formatAmount(minimumDeposit)}</b>
                </span>
                <span className="w-full">
                    Maximum Deposit: <b>{formatAmount(maximumDeposit)}</b>
                </span>
            </div>


            {stripeInitiatePayment === false &&
                <div className="bg-gray-100 text-gray-900 p-4">
                    <Elements stripe={stripePromise} options={options} className="text-gray-200">

                        <StripeForm {...props} />

                    </Elements>
                </div>

            }
            {stripeInitiatePayment === true &&
                <Card className="mx-auto w-full max-w-[24rem] my-10">
                    <CardBody className="pt-0 flex flex-col gap-4">

                        <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                            {errMsg !== '' &&
                                <li>{errMsg}<br /></li>
                            }

                        </ul>

                        <Input
                            variant="outlined"
                            label="Amount"
                            size="lg"
                            type="number"
                            id="amount"
                            ref={transactionAmountRef}
                            autoComplete="off"
                            aria-invalid={validTransactionAmount ? "false" : "true"}
                            aria-describedby="transactionAmountNote"
                            onChange={(e) => setTransactionAmount(e.target.value)}
                            value={transactionAmount}
                            onFocus={() => setTransactionAmountFocus(true)}
                            onBlur={() => setTransactionAmountFocus(false)}
                            error={transactionAmount && !validTransactionAmount ? true : false}
                            success={validTransactionAmount ? true : false}
                            required
                        />
                        <Typography
                            id="transactionAmountNote"
                            variant="small"
                            color="red"
                            className={"mt-2 flex items-center gap-1 font-normal" + transactionAmountRef && transactionAmount && !validTransactionAmount ? "flex" : "hidden"}
                        >
                            Must be greater or equal to 1 <br />
                        </Typography>




                    </CardBody>
                    <CardFooter className="pt-0">
                        {props.stripePaymentProcessing === true &&
                            <Button onClick={handleInitiatePayment} disabled={!validTransactionAmount || props.stripePaymentProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                                <span className="flex items-center justify-center gap-2 mx-auto">
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Processing...
                                </span>
                            </Button>
                        }
                        {props.stripePaymentProcessing === false &&
                            <Button onClick={handleInitiatePayment} disabled={!validTransactionAmount || props.stripePaymentProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                                Submit
                            </Button>
                        }
                    </CardFooter>
                </Card>
            }

        </div>
    )

}


export default StripePayment

