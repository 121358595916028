import { useRef, useState, useEffect } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Typography } from "@material-tailwind/react";
import axios from 'axios';
axios.defaults.withCredentials = true;


const PaypalPayment = () => {
 

    return (

        <div>
         
        </div>
    )
}

export default PaypalPayment