import airplaneCrashSprites from '../../assets/images/airplaneCrashSprites.png';
import styled, { keyframes } from 'styled-components';
const animation = keyframes`
  100% { background-position: -5000px; }
`;
export const AirplaneCrash = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  bottom: -80px;
  left: -50px;
  transform: scale(0.4,0.4);
  background: url(${airplaneCrashSprites}) left center;
  animation: ${animation} 10.0s steps(25) infinite; 

  @media (max-width: 768px) {
    bottom: -100px;
    left: -50px;
    transform: scale(0.3,0.3);
  }
`;