import { createSlice } from '@reduxjs/toolkit'

export const totalDepositsSlice = createSlice({
    name: 'totalDeposits',
    initialState: {
      value: 0,
    },
    reducers: {
      setTotalDeposits: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setTotalDeposits } = totalDepositsSlice.actions;

export default totalDepositsSlice.reducer;
