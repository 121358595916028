import { createSlice } from '@reduxjs/toolkit'

export const volumeSlice = createSlice({
    name: 'volume',
    initialState: {
      value: 0.3,
    },
    reducers: {
        setVolume: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setVolume } = volumeSlice.actions;

export default volumeSlice.reducer;
