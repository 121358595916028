import './App.css';
import Constants from './components/Constants';
import CrashGame from './components/CrashGame'

function App() {
  return (
   <div className=''>
    {/* <CrashGame /> */}
    <Constants />
   </div>
  );
}


export default App;
