import { useRef, useState, useEffect } from "react";
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Card, CardBody, CardFooter, CardHeader, Input, Textarea, Typography } from "@material-tailwind/react";
import axios from 'axios';
import { toast } from "react-toastify";
axios.defaults.withCredentials = true;

const TRANSACTION_ID_REGEX = /[\S\s]+[\S]+/;
const TRANSACTION_DETAILS_REGEX = /[\S\s]+[\S]+/;
const TRANSACTION_AMOUNT_REGEX = /(?!^$)([^\s])/;

const ManualPayment = (props) => {
    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const dispatch = useDispatch();
  
    const transactionDetailsRef = useRef();
    const transactionIdRef = useRef();
    const transactionAmountRef = useRef();
    const errRef = useRef();


    const minimumDeposit = JSON.parse(localStorage.getItem('minimumDeposit'));
    const maximumDeposit = JSON.parse(localStorage.getItem('maximumDeposit'));
    const minimumWithdrawal = JSON.parse(localStorage.getItem('minimumWithdrawal'));
    const maximumWithdrawal = JSON.parse(localStorage.getItem('maximumWithdrawal'));
    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));
    const manualPaymentInstructions = JSON.parse(localStorage.getItem('manualPaymentInstructions'));


    const userId = useSelector((state) => state.userId.value);


    const [errMsg, setErrMsg] = useState([]);

    const [transactionDetails, setTransactionDetails] = useState('');
    const [validTransactionDetails, setValidTransactionDetails] = useState(false);
    const [transactionDetailsFocus, setTransactionDetailsFocus] = useState(false);


    const [transactionId, setTransactionId] = useState('');
    const [validTransactionId, setValidTransactionId] = useState(false);
    const [transactionIdFocus, setTransactionIdFocus] = useState(false);

    const [transactionAmount, setTransactionAmount] = useState('');
    const [validTransactionAmount, setValidTransactionAmount] = useState(false);
    const [transactionAmountFocus, setTransactionAmountFocus] = useState(false);


    const [manualPaymentProcessing, setManualPaymentProcessing] = useState(false);

    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
      }

      const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    useEffect(() => {
        transactionIdRef.current.focus();
    }, [])

    useEffect(() => {
        transactionDetailsRef.current.focus();
    }, [])

    useEffect(() => {
        transactionAmountRef.current.focus();
    }, [])

    useEffect(() => {
        setValidTransactionDetails(TRANSACTION_DETAILS_REGEX.test(transactionDetails));
    }, [transactionDetails])

    useEffect(() => {
        setValidTransactionId(TRANSACTION_ID_REGEX.test(transactionId));
    }, [transactionId])

    useEffect(() => {
        if (transactionAmount >= Number(minimumDeposit) && transactionAmount <= Number(maximumDeposit)) {
            setValidTransactionAmount(true);
        } else {
            setValidTransactionAmount(false);
        }
    }, [transactionAmount,minimumDeposit, maximumDeposit])

    useEffect(() => {
    }, [transactionAmount])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setManualPaymentProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/recordManualTransaction`,
                { userId: userId, transaction_code: transactionId, details: transactionDetails, amount: transactionAmount },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            setManualPaymentProcessing(() => false);
            props.handleCloseAllPaymentMethods();
            props.handleOpenDeposit();
            successNotify('Transaction submitted successfully.');
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
                setErrMsg('No Server Response');
                errRef.current.scrollIntoView();
            } else {
                errorNotify('Transaction Failed');
                setErrMsg('Transaction Failed');
                errRef.current.scrollIntoView();
            }
            setManualPaymentProcessing(() => false);
        }
    }


    return (

        <div>
            <div className='prose text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: manualPaymentInstructions }} />
            <div className="grid grid-cols-2 p-4 gap-2 text-sm w-full text-orange-200 border rounded">
                <span className="w-full">
                    Minimum Deposit: <b>{formatAmount(minimumDeposit)}</b>
                </span>
                <span className="w-full">
                    Maximum Deposit: <b>{formatAmount(maximumDeposit)}</b>
                </span>
            </div>
            <Card className="mx-auto w-full max-w-[24rem] my-10">
                <CardBody className="pt-0 flex flex-col gap-4">

                    <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                        {errMsg !== '' &&
                            <li>{errMsg}<br /></li>
                        }

                    </ul>


                    <Input
                        variant="outlined"
                        label="Transaction Id"
                        size="lg"
                        type="text"
                        id="transactionId"
                        ref={transactionIdRef}
                        autoComplete="off"
                        aria-invalid={validTransactionId ? "false" : "true"}
                        aria-describedby="transactionIdNote"
                        onChange={(e) => setTransactionId(e.target.value)}
                        value={transactionId}
                        onFocus={() => setTransactionIdFocus(true)}
                        onBlur={() => setTransactionIdFocus(false)}
                        error={transactionId && !validTransactionId ? true : false}
                        success={validTransactionId ? true : false}
                        required
                    />
                    <Typography
                        id="transactionIdNote"
                        variant="small"
                        color="red"
                        className={"mt-2 flex items-center gap-1 font-normal" + transactionIdRef && transactionId && !validTransactionId ? "flex" : "hidden"}
                    >
                        Cannot be empty! <br />
                        Must be more than one character!
                    </Typography>

                    <Input
                        variant="outlined"
                        label="Amount"
                        size="lg"
                        type="number"
                        id="amount"
                        ref={transactionAmountRef}
                        autoComplete="off"
                        aria-invalid={validTransactionAmount ? "false" : "true"}
                        aria-describedby="transactionAmountNote"
                        onChange={(e) => setTransactionAmount(e.target.value)}
                        value={transactionAmount}
                        onFocus={() => setTransactionAmountFocus(true)}
                        onBlur={() => setTransactionAmountFocus(false)}
                        error={transactionAmount && !validTransactionAmount ? true : false}
                        success={validTransactionAmount ? true : false}
                        required
                    />
                    <Typography
                        id="transactionAmountNote"
                        variant="small"
                        color="red"
                        className={"mt-2 flex items-center gap-1 font-normal" + transactionAmountRef && transactionAmount && !validTransactionAmount ? "flex" : "hidden"}
                    >
                        Must be greater than {minimumDeposit}! <br />
                        Must be less than {maximumDeposit}! <br />
                    </Typography>

                    <Textarea
                        variant="outlined"
                        label="Transaction Details"
                        size="lg"
                        type="text"
                        id="transactionDetails"
                        ref={transactionDetailsRef}
                        autoComplete="off"
                        aria-invalid={validTransactionDetails ? "false" : "true"}
                        aria-describedby="transactionDetailsNote"
                        onChange={(e) => setTransactionDetails(e.target.value)}
                        value={transactionDetails}
                        onFocus={() => setTransactionDetailsFocus(true)}
                        onBlur={() => setTransactionDetailsFocus(false)}
                        error={transactionDetails && !validTransactionDetails ? true : false}
                        success={validTransactionDetails ? true : false}
                        required
                    />

                    <Typography
                        id="transactionIdNote"
                        variant="small"
                        color="red"
                        className={"mt-2 flex items-center gap-1 font-normal" + transactionDetailsRef && transactionDetails && !validTransactionDetails ? "flex" : "hidden"}
                    >
                        Cannot be empty! <br />
                        Must be more than one character!
                    </Typography>



                </CardBody>
                <CardFooter className="pt-0">
                    {manualPaymentProcessing === true &&
                        <Button onClick={handleSubmit} disabled={!validTransactionDetails || !validTransactionId || !validTransactionAmount || manualPaymentProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                            <span className="flex items-center justify-center gap-2 mx-auto">
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </span>
                        </Button>
                    }
                    {manualPaymentProcessing === false &&
                        <Button onClick={handleSubmit} disabled={!validTransactionDetails || !validTransactionId || !validTransactionAmount ? true : false} color='indigo' variant="gradient" fullWidth>
                            Submit
                        </Button>
                    }
                </CardFooter>
            </Card>
        </div>
    )
}

export default ManualPayment