import React, { useState } from 'react';
import ShieldImage from '../assets/images/shield.png';
import { ShieldCheckIcon } from "@heroicons/react/24/solid";

import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Typography,
    Input,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";

export default function Fair() {
    const [openFair, setOpenFair] = React.useState(false);
    const [hashKey, setHashKey] = useState('');
    const [hashSalt, setHashSalt] = useState('');
    const [hashHash, setHashHash] = useState('');

    const handleOpenFair = () => setOpenFair(!openFair);

    const sha512 = (str) => {
        return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
            return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
        });
    }

    const handleHash = () => {
        var str = hashKey + hashSalt;
        sha512(str)
            .then(x => setHashHash(() => x));
    }
    const handleHashKeyChange = (event) => {
        var newHashKey = event.target.value;
        setHashKey(() => newHashKey);
    };
    const handleHashSaltChange = (event) => {
        var newHashSalt = event.target.value;
        setHashSalt(() => newHashSalt);
    };



    return (
        <>
            
                <div>
                    <div>
                        <button onClick={handleOpenFair} className=" flex justify-center p-1 md:my-0.5 border border-[#2e2e2e] rounded  my-0.5 text-xs  font-medium  text-gray-50 bg-[#2e2e2e] hover:border-[#ffbc00] active:border-0 transition duration-700 ease-in-out">
                            <span className="mr-1">
                                <ShieldCheckIcon className=" h-6 text-[#ffbc00]" strokeWidth={2.0} />
                            </span>
                            <span className="flex text-gray-200 text-xs pt-1 -pb-1" >Provably Fair</span>
                        </button>
                    </div>

                    <Dialog
                        open={openFair}
                        handler={handleOpenFair}
                        animate={{
                            mount: { scale: 1, y: 0 },
                            unmount: { scale: 0.9, y: -100 },
                        }}
                        size={"xs"}
                        className="bg-[#181c3a]"
                    >
                        <DialogHeader className="text-gray-300">Provably Fair</DialogHeader>


                        <DialogBody divider className="h-80 overflow-y-scroll break-words text-xs text-gray-300">
                            <Button hidden color='indigo' className='mb-2' variant="gradient" fullWidth> </Button>

                            <div className='prose text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: localStorage.getItem('provablyFairInfo') }} />


                            <Card className="mx-auto w-full max-w-[24rem] my-10">
                                <CardHeader
                                    variant="gradient"
                                    color="indigo"
                                    className="mb-4 grid h-20 place-items-center"
                                >
                                    <Typography variant="h3" color="white">
                                        Check Hash Code
                                    </Typography>
                                </CardHeader>
                                <CardBody className="flex flex-col gap-4">
                                    <Input
                                        id="fairKey"
                                        label="Key"
                                        size="lg"
                                        value={hashKey}
                                        onChange={handleHashKeyChange}
                                    />
                                    <Input
                                        id="fairSalt"
                                        label="Salt"
                                        size="lg"
                                        value={hashSalt}
                                        onChange={handleHashSaltChange}

                                    />
                                    <Input
                                        id="fairHash"
                                        label="Hash Code"
                                        size="lg"
                                        value={hashHash}
                                        readOnly
                                    />

                                </CardBody>
                                <CardFooter className="pt-0">
                                    <Button color='indigo' variant="gradient" onClick={handleHash} fullWidth>
                                        CHECK
                                    </Button>

                                </CardFooter>
                            </Card>

                        </DialogBody>
                        <DialogFooter>
                            <Button
                                variant="text"
                                color="red"
                                onClick={handleOpenFair}
                                className="mr-1"
                            >
                                <span>Cancel</span>
                            </Button>
                            <Button variant="gradient" color="green" onClick={handleOpenFair}>
                                <span>Confirm</span>
                            </Button>
                        </DialogFooter>
                    </Dialog>

                </div>
            
        </>
    )
}
