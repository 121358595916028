import { createSlice } from '@reduxjs/toolkit'

export const openWingipayPaymentSlice = createSlice({
    name: 'openWingipayPayment',
    initialState: {
      value: false,
    },
    reducers: {
        setOpenWingipayPayment: (state, action) => {
        state.value = action.payload
      },
    },
})
 
// Action creators are generated for each case reducer function
export const { setOpenWingipayPayment } = openWingipayPaymentSlice.actions;

export default openWingipayPaymentSlice.reducer;


