import { useRef, useState, useEffect } from "react";
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Context } from './Constants';
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Typography } from "@material-tailwind/react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

import axios from 'axios';
import { toast } from "react-toastify";
import { setUserId } from "../features/userIdSlice";
import { setPhone } from "../features/phoneSlice";
import { setEmail } from "../features/emailSlice";
axios.defaults.withCredentials = true;

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ResetPassword = (props) => {
    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);


    const accountBalance = useSelector((state) => state.accountBalance.value);
    const email = useSelector((state) => state.email.value);
    const phone = useSelector((state) => state.phone.value);
    const userId = useSelector((state) => state.userId.value);

    const registrationType = JSON.parse(localStorage.getItem('registrationType'));


    const dispatch = useDispatch();
    const errRef = useRef();
    const phoneRef = useRef();


    const [submitResetEmail, setSubmitResetEmail] = useState(true);
    const [submitResetCode, setSubmitResetCode] = useState(false);
    const [submitResetPassword, setSubmitResetPassword] = useState(false);

    const [resetCode, setResetCode] = useState('');

    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);


    const [validCode, setValidCode] = useState(false);
    const [codeFocus, setCodeFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [password_confirmation, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [passwordResetProcessing, setPasswordResetProcessing] = useState(false);


    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
    const countryCode = JSON.parse(localStorage.getItem('countryCode'));



    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        if (registrationType === 'phone_number_only') {

            if (phone.trim().length < 10) {
                setValidPhone(() => false);
            } else {
                setValidPhone(() => true);
            }
        }
    }, [phone, registrationType])

    useEffect(() => {
        if (resetCode.trim().length < 6) {
            setValidCode(() => false);
        } else {
            setValidCode(() => true);
        }
    }, [resetCode])

    useEffect(() => {
        if (password.trim().length < 4) {
            setValidPassword(() => false);
        } else {
            setValidPassword(() => true);
        }
    }, [password])

    useEffect(() => {
        setValidMatch(password === password_confirmation);
    }, [password, password_confirmation])

    useEffect(() => {
        setErrMsg('');
    }, [email, phone, password, password_confirmation])

    const handleSubmitResetEmail = async (e) => {
        e.preventDefault();
        try {
            setPasswordResetProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/submitResetEmail/`,
                { email: email, phone: phone },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json'
                    },
                }
            );

            setSubmitResetEmail(() => false);
            setSubmitResetCode(() => true);
            if (registrationType === 'phone_number_only') {
                successNotify('A password reset code has been sent to your Phone.');
            } else {
                successNotify('A password reset code has been sent to your Email.');
            }
            setPasswordResetProcessing(() => false);
            setErrMsg('');
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
                setErrMsg('No Server Response');
                errRef.current.scrollIntoView();
            } else {
                errorNotify('Verification Failed');
                errRef.current.scrollIntoView();
                setErrMsg(err.response.data.message);
            }
            setPasswordResetProcessing(() => false);
        }

    }



    const handleSubmitResetCode = async (e) => {
        e.preventDefault();
        try {
            setPasswordResetProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/submitResetCode/`,
                { resetCode },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json'
                    },
                }
            );
            setSubmitResetCode(() => false);
            setSubmitResetPassword(() => true);
            dispatch(setUserId(response?.data.data.user_id));
            successNotify('The code provided has been verified successfully.');
            setPasswordResetProcessing(() => false);
            setErrMsg('');
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
                setErrMsg('No Server Response');
                errRef.current.scrollIntoView();
            } else {
                errorNotify('Code verification Failed');
                errRef.current.scrollIntoView();
                setErrMsg(err.response.data.message);
            }
            setPasswordResetProcessing(() => false);
        }

    }


    const handleSubmitResetPassword = async (e) => {
        e.preventDefault();
        try {
            setPasswordResetProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/submitResetPassword/`,
                { password, password_confirmation, id: userId },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json'
                    },
                }
            );

            props.handleOpenPasswordReset();
            props.handleOpenAuth();
            props.setOpenLogin(() => true);
            successNotify('Password reset successful.');
            setPasswordResetProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
                setErrMsg('No Server Response');
                errRef.current.scrollIntoView();
            } else {
                errorNotify('Password reset Failed!');
                errRef.current.scrollIntoView();
                setErrMsg(err.response.data.message);
            }
            setPasswordResetProcessing(() => false);
        }

    }




    return (

        <div>
            <Card className="mx-auto w-full max-w-[24rem] my-10">
                <CardHeader
                    variant="gradient"
                    color="indigo"
                    className="mb-4 grid h-20 place-items-center"
                >
                    <Typography variant="h3" color="white">
                        {submitResetEmail === true &&
                            <>
                                {registrationType === 'phone_number_only' &&
                                    <>
                                        <span>Provide Phone</span>
                                    </>
                                }
                                {registrationType === 'email_and_username' &&
                                    <>
                                        <span>Provide Email</span>
                                    </>
                                }
                            </>
                        }
                        {phoneError !== '' &&
                            <li>{phoneError}<br /></li>
                        }
                        {submitResetCode === true &&
                            <span>Enter Code</span>
                        }
                        {submitResetPassword === true &&
                            <span>New Password</span>
                        }
                    </Typography>
                </CardHeader>
                <CardBody className="pt-0 flex flex-col gap-4">

                    <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                        {errMsg !== '' &&
                            <li>{errMsg}<br /></li>
                        }
                    </ul>


                    {submitResetEmail === true &&
                        <>


                            {registrationType === 'phone_number_only' &&
                                <>
                                    <PhoneInput
                                        variant="outlined"
                                        label="Phone"
                                        size="lg"
                                        id="phone"
                                        country={countryCode}
                                        enableSearch={true}
                                        value={phone}
                                        onChange={e => dispatch(setPhone(e))}
                                        ref={phoneRef}
                                        autoComplete="off"
                                        required
                                        aria-invalid={validPhone ? "false" : "true"}
                                        aria-describedby="eidPhone"
                                        onFocus={() => setPhoneFocus(true)}
                                        onBlur={() => setPhoneFocus(false)}
                                        error={phone && !validPhone ? true : false}
                                        success={validPhone ? true : false}

                                    />

                                    <Typography
                                        id="eidPhone"
                                        variant="small"
                                        color="red"
                                        className={"mt-2 flex items-center gap-1 font-normal " + phoneFocus && phone && !validPhone ? "flex" : "hidden"}
                                    >
                                        Must be a valid phone number.
                                    </Typography>
                                </>
                            }


                            {registrationType === 'email_and_username' &&
                                <>

                                    <Input
                                        variant="outlined"
                                        label="Provide your Email"
                                        size="lg"
                                        type="email"
                                        id="resetEmail"
                                        autoComplete="off"
                                        onChange={(e) => dispatch(setEmail(e.target.value))}
                                        value={email}
                                        required
                                        aria-invalid={validEmail ? "false" : "true"}
                                        aria-describedby="emailnote"
                                        onFocus={() => setEmailFocus(true)}
                                        onBlur={() => setEmailFocus(false)}
                                        error={email && !validEmail ? true : false}
                                        success={validEmail ? true : false}
                                    />

                                    <Typography
                                        id="emailnote"
                                        variant="small"
                                        color="red"
                                        className={"mt-2 flex items-center gap-1 font-normal " + emailFocus && email && !validEmail ? "flex" : "hidden"}
                                    >
                                        Must be a valid email address.
                                    </Typography>

                                </>
                            }




                        </>
                    }


                    {submitResetCode === true &&

                        <>
                            {registrationType === 'phone_number_only' &&
                                <Typography
                                    className="my-2 flex items-center gap-1 font-bold text-lg "
                                >
                                    A verification code was sent to your phone, kindly provide it below.<br />
                                </Typography>
                            }
                            {registrationType === 'email_and_username' &&
                                <Typography
                                    className="my-2 flex items-center gap-1 font-bold text-lg "
                                >
                                    A verification code was sent to your email, kindly provide it below.<br />
                                </Typography>
                            }

                            <Input
                                variant="outlined"
                                label="Verification Code"
                                size="lg"
                                type="text"
                                id="resetCode"
                                autoComplete="off"
                                onChange={(e) => setResetCode(e.target.value)}
                                value={resetCode}
                                required
                                aria-invalid={validCode ? "false" : "true"}
                                aria-describedby="codenote"
                                onFocus={() => setCodeFocus(true)}
                                onBlur={() => setCodeFocus(false)}
                                error={resetCode && !validCode ? true : false}
                                success={validCode ? true : false}
                            />

                            <Typography
                                id="codenote"
                                variant="small"
                                color="red"
                                className={"mt-2 flex items-center gap-1 font-normal " + codeFocus && resetCode && !validCode ? "flex" : "hidden"}
                            >
                                Minimum of 6 characters.<br />
                            </Typography>
                        </>
                    }

                    {submitResetPassword === true &&
                        <>
                            <div className="relative flex w-full max-w-[24rem]">
                                <Input
                                    variant="outlined"
                                    label="New Password"
                                    size="lg"
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                    aria-invalid={validPassword ? "false" : "true"}
                                    aria-describedby="passwordnote"
                                    onFocus={() => setPasswordFocus(true)}
                                    onBlur={() => setPasswordFocus(false)}
                                    error={password && !validPassword ? true : false}
                                    success={validPassword ? true : false}
                                />
                                <Button
                                    size="sm"
                                    variant="text"
                                    onClick={handleShowPassword}
                                    className="!absolute right-1 top-1 rounded"
                                >
                                    {!showPassword &&
                                        <EyeIcon className="h-6 w-6 text-gray-700" />
                                    }
                                    {showPassword &&
                                        <EyeSlashIcon className="h-6 w-6 text-gray-700" />
                                    }
                                </Button>
                            </div>

                            <Typography
                                id="passwordnote"
                                variant="small"
                                color="red"
                                className={"mt-2 flex items-center gap-1 font-normal " + password && passwordFocus && !validPassword ? "flex" : "hidden"}
                            >
                                Minimum of 4 characters.<br />
                            </Typography>

                            <div className="relative flex w-full max-w-[24rem]">

                                <Input
                                    variant="outlined"
                                    label="Confirm Password"
                                    size="lg"
                                    type={showPassword ? "text" : "password"}
                                    id="confirm_password"
                                    onChange={(e) => setMatchPassword(e.target.value)}
                                    value={password_confirmation}
                                    required
                                    aria-invalid={validMatch ? "false" : "true"}
                                    aria-describedby="confirmnote"
                                    onFocus={() => setMatchFocus(true)}
                                    onBlur={() => setMatchFocus(false)}
                                    error={password_confirmation && !validMatch ? true : false}
                                    success={password_confirmation && validMatch ? true : false}

                                />
                                <Button
                                    size="sm"
                                    variant="text"
                                    onClick={handleShowPassword}
                                    className="!absolute right-1 top-1 rounded"
                                >
                                    {!showPassword &&
                                        <EyeIcon className="h-6 w-6 text-gray-700" />
                                    }
                                    {showPassword &&
                                        <EyeSlashIcon className="h-6 w-6 text-gray-700" />
                                    }
                                </Button>
                            </div>

                            <Typography
                                id="passwordnote"
                                variant="small"
                                color="red"
                                className={"mt-2 flex items-center gap-1 font-normal " + password_confirmation && matchFocus && !validMatch ? "flex" : "hidden"}
                            >
                                Must match the first password input field.
                            </Typography>
                        </>
                    }

                </CardBody>
                <CardFooter className="pt-0">
                    {passwordResetProcessing === true &&
                        <Button disabled={passwordResetProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                            <span className="flex items-center justify-center gap-2 mx-auto">
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </span>
                        </Button>
                    }

                    {passwordResetProcessing === false &&
                        <span>
                            {submitResetEmail === true &&
                                <>
                                    {registrationType === 'phone_number_only' &&
                                        <Button onClick={handleSubmitResetEmail} disabled={!validPhone ? true : false} color='indigo' variant="gradient" fullWidth>
                                            Submit
                                        </Button>
                                    }
                                    {registrationType === 'email_and_username' &&
                                        <Button onClick={handleSubmitResetEmail} disabled={!validEmail ? true : false} color='indigo' variant="gradient" fullWidth>
                                            Submit
                                        </Button>
                                    }
                                </>
                            }
                            {submitResetCode === true &&
                                <Button onClick={handleSubmitResetCode} disabled={!validCode ? true : false} color='indigo' variant="gradient" fullWidth>
                                    Submit
                                </Button>
                            }
                            {submitResetPassword === true &&
                                <Button onClick={handleSubmitResetPassword} disabled={!validPassword || !validMatch ? true : false} color='indigo' variant="gradient" fullWidth>
                                    Submit
                                </Button>
                            }
                        </span>
                    }

                </CardFooter>
            </Card>

        </div>
    )
}


export default ResetPassword