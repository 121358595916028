import React, { useEffect, useState } from 'react';
import ShieldImage from '../assets/images/shield.png';
import { useSelector, useDispatch } from 'react-redux';
import {
    Card,
    CardBody,
    CardHeader,
    Typography,
    Input,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import Pusher from 'pusher-js';
import { setStatisticsResults } from '../features/statisticsResultsSlice';



export default function CrashStatisticsResults() {
    const RESULTS_TABLE_HEAD = ["Round ID", "Results", "Check"];

    const statisticsResults = useSelector((state) => state.statisticsResults.value);
    const previousGames = useSelector((state) => state.previousGames.value);
    const [websocketsDomain, setWebsocketsDomain] = useState(window.WEBSOCKETS_DOMAIN);
    const [websocketsConnection, setWebsocketsConnection] = useState(window.WEBSOCKETS_CONNECTION);
    const [pusherAppKey, setPusherAppKey] = useState(window.PUSHER_APP_KEY);

    const authentication = useSelector((state) => state.authentication.value);
    const firstBanner = JSON.parse(localStorage.getItem('firstBanner'));
    const secondBanner = JSON.parse(localStorage.getItem('secondBanner'));

    const dispatch = useDispatch();

    const [statisticsResult, setStatisticsResult] = useState([]);;


    const [openFair2, setopenFair2] = React.useState(false);
    const handleopenFair2 = () => setopenFair2(!openFair2);


    const viewCoefficient = (id) => {
        setStatisticsResult(statisticsResults.find(obj => {
            return obj.id === id
        }));
        setopenFair2(true);
    }

    const viewCoefficientUsed = (id) => {
        setStatisticsResult(previousGames.find(obj => {
            return obj.id === id
        }));
        setopenFair2(true);
    }


    const formatCoefficient = (coefficient) => {
        if (coefficient === undefined) {
            return null;
        } else {
            var text1 = "x";
            var num = parseFloat(coefficient).toFixed(2);
            let result = text1.concat(num);
            return result;
        }
    };



    useEffect(() => {


        if (websocketsConnection === 'self_hosted') {
            //self hosted websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                wsHost: websocketsDomain,
                wsPort: 443,
                forceTLS: true,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 443,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],
            });
        } else if (websocketsConnection === 'local') {
            //local server websockets
            var pusher = new Pusher(pusherAppKey, {
                broadcaster: 'pusher',
                // wsHost: websocketsDomain,
                wsHost: '127.0.0.1',
                wsPort: 6001,
                forceTLS: false,
                disableStats: true,
                cluster: 'eu',//added this line
                wssPort: 6001,
                encrypted: true,
                transports: ['websocket'],
                enabledTransports: ['ws', 'wss'],

            });
        } else {
            // paid pusher configuration
            var pusher = new Pusher(pusherAppKey, {
                cluster: 'eu'
            });
        }


        const fetchData = async () => {


            const ResultCoefficientsChannel = pusher.subscribe('ResultCoefficientsChannel');
            ResultCoefficientsChannel.bind('ResultCoefficientsEvent', function (data) {

                dispatch(setStatisticsResults(data.resultCoefficients));

            })
        }
        fetchData();
    }, [
    ]);




    return (
        <>

            <div className='overflow-y-scroll h-[320px]   pb-14 md:h-[580px]'>
                <Card className="w-full h-full rounded-none bg-[#181c3a]">
                    <table className="w-full min-w-max table-auto text-left bg-[#181c3a]">
                        <thead>
                            <tr>
                                {RESULTS_TABLE_HEAD.map((head) => (
                                    <th key={head} className="border-b bg-[#181c3a] p-2 text-gray-400 uppercase">
                                        <Typography
                                            variant="small"
                                            color="inherit"
                                            className="text-xs leading-none font-medium"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {statisticsResults.map(({ id, hash, check }, index) => {
                                const classes = "px-1 py-0 bg-[#181c3a] border-b-2 border-[#2e335c]";

                                return (
                                    <tr key={id} className="rounded-md text-gray-400 ">
                                        <td className={classes}>
                                            <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                {id}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                {/* <>
                                                    {id === roundId &&
                                                        <Spinner color="indigo" className="h-4 w-4" />
                                                    }
                                                    {id !== roundId &&
                                                        <span>soon</span>
                                                    }
                                                </> */}
                                                <span>soon</span>
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <button onClick={() => viewCoefficient(id)} className="p-0 m-0 border-none w-full">
                                                <img className=" h-5 m-0 pt-1" src={ShieldImage} alt="Provably Fair" />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}


                            {previousGames.map(({ id, coefficient, check }, index) => {
                                const classes = "px-1 py-0 bg-[#181c3a] border-b-2 border-[#2e335c]";

                                return (
                                    <tr key={id} className="rounded-md text-gray-400 ">
                                        <td className={classes}>
                                            <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                {id}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            {/* <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                {formatCoefficient(coefficient)}
                                            </Typography> */}
                                            <span >
                                                {coefficient < 2 &&
                                                    <button className="h-5 px-1 py-0.5 text-gray-100 text-xs rounded-md bg-none border border-[#0652dd] bg-[#0652dd] hover:bg-transparent hover:border-[#0652dd] hover:text-[#0652dd] active:border-0 transition duration-700 ease-in-out font-bold">{formatCoefficient(coefficient)}</button>
                                                }
                                                {(coefficient >= 2) && (coefficient < 10) &&
                                                    <button className="h-5 px-1 py-0.5 text-[#111111] text-xs rounded-md bg-none border border-[#8fc92c] bg-[#8fc92c] hover:bg-transparent hover:border-[#8fc92c] hover:text-[#8fc92c] active:border-0 transition duration-700 ease-in-out font-bold">{formatCoefficient(coefficient)}</button>
                                                }
                                                {coefficient >= 10 &&
                                                    <button className="h-5 px-1 py-0.5 text-[#111111] text-xs rounded-md bg-none border border-[#ffbc00] bg-[#ffbc00] hover:bg-transparent hover:border-[#ffbc00] hover:text-[#ffbc00] active:border-0 transition duration-700 ease-in-out font-bold">{formatCoefficient(coefficient)}</button>
                                                }
                                            </span>
                                        </td>
                                        <td className={classes}>
                                            <button onClick={() => viewCoefficientUsed(id)} className="p-0 m-0 border-none w-full">
                                                <img className=" h-5 m-0 pt-1" src={ShieldImage} alt="Provably Fair" />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}

                        </tbody>
                    </table>
                </Card>

                <Dialog
                    open={openFair2}
                    handler={handleopenFair2}
                    animate={{
                        mount: { scale: 1, y: 0 },
                        unmount: { scale: 0.9, y: -100 },
                    }}
                    size={"xs"}
                    className="bg-[#181c3a]"
                >
                    <DialogHeader className="text-gray-300 ">
                        <Typography variant="h3" color="white" className="w-full flex justify-center">
                            Round ID {statisticsResult.id}
                        </Typography>
                    </DialogHeader>


                    <DialogBody divider className="h-80 overflow-y-scroll text-xs
 text-gray-300">
                        <Button className='bg-indigo-700 shadow-none m-0 p-0'> </Button>
                        <div className="w-full relative mx-auto mb-12 bg-[#181c3a] h-10">

                            <div className="absolute inset-0 flex justify-center text-gray-50 text-3xl font-black mx-auto">
                                <div className=" pl-10 flex content-center bg-cover h-10 w-40">
                                    {formatCoefficient(statisticsResult.coefficient)}
                                </div>
                            </div>
                        </div>

                        <Card className="mx-auto w-full max-w-[24rem] my-10">
                            <CardHeader
                                variant="gradient"
                                color="orange"
                                className="mb-4 grid h-20 place-items-center"
                            >
                                <Typography variant="h3" color="white">
                                    Hash Code
                                </Typography>
                            </CardHeader>
                            <CardBody className="flex flex-col gap-4">
                                <Input label="Key" size="lg" id="resultKey"
                                    defaultValue={statisticsResult.key}
                                />
                                <Input label="Salt" size="lg" id="resultSalt"
                                    defaultValue={statisticsResult.salt}
                                />
                                <Input id="resultHash" label="Hash Code" size="lg" value={statisticsResult.hash} readOnly />

                            </CardBody>

                        </Card>
                        <div className="break-words">
                            <div className="pr-f-text-wrp">
                                <p className="pr-f-text"
                                    title="Before the start of the game round, players are provided with a &quot;Hash Code*&quot; - a coded text combination. &quot;Hash code*&quot; has the odds that will represent the result of the game. That is, the odds that will be the result of the game is determined not during the game, but in advance for 5 rounds, and is given to the players in coded form. For example: &quot;Hash code*&quot;.">
                                    Before the start of the game round, players are provided with a "Hash Code*" - a coded text combination. "Hash
                                    code*" has the odds that will represent the result of the game. That is, the odds that will be the result of the
                                    game is determined not during the game, but in advance for 5 rounds, and is given to the players in coded form. For
                                    example: "Hash code*".</p><br />

                                <p className="pr-f-text w-b-ba">
                                    CE545547F79996444E87E12DC16F647C8E8EA2A0D948319D22DC930E8728047E2F91285D1CFD8805E9F764982D8328D11F3456354FA208EBEA78FA6693C03DB3
                                </p><br />
                                <p className="pr-f-text"
                                    title="At the end of the game round, players are given a &quot;Key *&quot; and additional input parameter - &quot;Salt*&quot; to get a &quot;Hash code*&quot;. With them, each player can duplicate and encode this combination to make sure the encrypted &quot;Key*&quot; matches the &quot;Hash Code*&quot; given by the system in advance. This latest technology proves the honesty of the game and assures the players that the number that will be the result of the game is determined not during the game, but in advance for 5 rounds.">
                                    At the end of the game round, players are given a "Key *" and additional input parameter - "Salt*" to get a "Hash
                                    code*". With them, each player can duplicate and encode this combination to make sure the encrypted "Key*" matches
                                    the "Hash Code*" given by the system in advance. This latest technology proves the honesty of the game and assures
                                    the players that the number that will be the result of the game is determined not during the game, but in advance
                                    for 5 rounds.</p><br />
                                <p className="pr-f-text"
                                    title="What is &quot;Salt*&quot;? It is a random combination of letters and numbers. For example: kqDff7STE6jOWTg1l9657A==">
                                    What is "Salt*"? It is a random combination of letters and numbers. For example: kqDff7STE6jOWTg1l9657A==</p><br />
                                <p className="pr-f-text" title="What is the &quot;Key*&quot;? It is a text combination of four parameters:">What is the
                                    "Key*"? It is a text combination of four parameters:</p><br />
                                <p className="pr-f-text" title="1. Game round ID;">1. Game round ID;</p><br />
                                <p className="pr-f-text" title="2. The odds that will be the result of the game">2. The odds that will be the result of
                                    the game</p><br />
                                <p className="pr-f-text" title="3. The word &quot;Crash&quot;;">3. The word "Crash";</p><br />
                                <p className="pr-f-text" title="4. Random combination of letters and numbers.">4. Random combination of letters and
                                    numbers.</p><br />
                                <p className="pr-f-text"
                                    title="At the end of the game, by clicking on the &quot;Check&quot; button in the &quot;Results&quot; section, the player will see the &quot;Key&quot; as follows:">
                                    At the end of the game, by clicking on the "Check" button in the "Results" section, the player will see the "Key" as
                                    follows:</p><br />
                                <p className="pr-f-text"
                                    title="Game round ID (for example: 648154); the odds that will be the result of the game (for example, 2); The word &quot;Crash&quot; and a random combination of letters and numbers: 38f30925-e85a-41ee-8fbf-b326162c4e1f. The &quot;Key*&quot; in full form in this case will be: 648154_2_Crash_38f30925-e85a-41ee-8fbf-b326162c4e1f">
                                    Game round ID (for example: 648154); the odds that will be the result of the game (for example, 2); The word "Crash"
                                    and a random combination of letters and numbers: 38f30925-e85a-41ee-8fbf-b326162c4e1f. The "Key*" in full form in
                                    this case will be: 648154_2_Crash_38f30925-e85a-41ee-8fbf-b326162c4e1f</p><br />
                                <p className="pr-f-text"
                                    title="If we use &quot;Salt*&quot; (for example kqDff7STE6jOWTg1l9657A==) to encrypt &quot;Key*&quot; with SHA512 generator, we will get the following &quot;Hash code*&quot;.">
                                    If we use "Salt*" (for example kqDff7STE6jOWTg1l9657A==) to encrypt "Key*" with SHA512 generator, we will get the
                                    following "Hash code*".</p><br />
                                <p className="pr-f-text w-b-ba">
                                    CE545547F79996444E87E12DC16F647C8E8EA2A0D948319D22DC930E8728047E2F91285D1CFD8805E9F764982D8328D11F3456354FA208EBEA78FA6693C03DB3
                                </p><br />
                                <p className="pr-f-text"
                                    title="To get the &quot;Hash code*&quot; you can click the &quot;Check&quot; button in the &quot;Results&quot; section and perform the check. You can also check on any online server that has a &quot;SHA512 With Salt&quot; generator.">
                                    To get the "Hash code*" you can click the "Check" button in the "Results" section and perform the check. You can
                                    also check on any online server that has a "SHA512 With Salt" generator.</p><br />
                            </div>
                        </div>



                    </DialogBody>
                    <DialogFooter>
                        <Button
                            variant="text"
                            color="red"
                            onClick={handleopenFair2}
                            className="mr-1"
                        >
                            <span>Cancel</span>
                        </Button>
                        <Button variant="gradient" color="green" onClick={handleopenFair2}>
                            <span>Confirm</span>
                        </Button>
                    </DialogFooter>
                </Dialog>
            </div>

        </>
    )
}
