import { configureStore } from '@reduxjs/toolkit';
import accountBalanceReducer from '../features/accountBalanceSlice';
import authenticationReducer from '../features/authenticationSlice';
import previousGamesReducer from '../features/previousGamesSlice';
import userIdReducer from '../features/userIdSlice';
import myBetsReducer from '../features/myBetsSlice';
import liveBetsReducer from '../features/liveBetsSlice';
import messagesReducer from '../features/messagesSlice';
import statisticsResultsReducer from '../features/statisticsResultsSlice';
import usernameReducer from '../features/usernameSlice';
import phoneReducer from '../features/phoneSlice';
import emailReducer from '../features/emailSlice';
import nameReducer from '../features/nameSlice';
import musicReducer from '../features/musicSlice';
import volumeReducer from '../features/volumeSlice';
import gameModeReducer from '../features/gameModeSlice';
import encryptionKeyReducer from '../features/encryptionKeySlice';
import openMpesaPaymentReducer from '../features/openMpesaPaymentSlice';
import openDepositReducer from '../features/openDepositSlice';
import openDusupayPaymentReducer from '../features/openDusupayPaymentSlice';
import openWingipayPaymentReducer from '../features/openWingipayPaymentSlice';
import totalDepositsReducer from '../features/totalDepositsSlice';


export default configureStore({
  reducer: {
    accountBalance: accountBalanceReducer, 
    authentication: authenticationReducer, 
    previousGames: previousGamesReducer, 
    userId: userIdReducer, 
    myBets: myBetsReducer, 
    liveBets: liveBetsReducer, 
    messages: messagesReducer, 
    statisticsResults: statisticsResultsReducer, 
    username: usernameReducer, 
    phone: phoneReducer, 
    email: emailReducer, 
    name: nameReducer, 
    music: musicReducer, 
    volume: volumeReducer, 
    gameMode: gameModeReducer, 
    encryptionKey: encryptionKeyReducer, 
    openMpesaPayment: openMpesaPaymentReducer,
    openDeposit: openDepositReducer,
    openDusupayPayment: openDusupayPaymentReducer,
    openWingipayPayment: openWingipayPaymentReducer,
    totalDeposits: totalDepositsReducer,
  },
})