import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMyBets } from '../../features/myBetsSlice';
import { addMyBets } from '../../features/myBetsSlice';

import Pusher from 'pusher-js';
import Moment from 'react-moment';

import ShieldImage from '../../assets/images/shield.png';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Typography,
    Input,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { ClockIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon, DocumentMagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';




export default function MelbetHistory() {
    const HISTORY_TABLE_HEAD = ["TIME", "ROUND ID", "ODDS", "AMOUNT", "STATUS", "WIN AMOUNT"];

    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));


    const myBets = useSelector((state) => state.myBets.value);
    const dispatch = useDispatch();

    const authentication = useSelector((state) => state.authentication.value);
    const firstBanner = JSON.parse(localStorage.getItem('firstBanner'));
    const secondBanner = JSON.parse(localStorage.getItem('secondBanner'));



    const formatCoefficient = (coefficient) => {
        if (coefficient !== null) {
            var text1 = "x";
            var num = parseFloat(coefficient).toFixed(2);
            let result = text1.concat(num);
            return result;
        }
        else {
            return coefficient;
        }
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
    }

    return (
        <div className='h-[320px]  pb-14 md:h-[580px]'>
            <Card className="w-full rounded-none bg-[#181c3a] overflow-y-scroll">

                <table className="w-full min-w-max table-auto text-left bg-[#181c3a]">
                    <thead>
                        <tr>
                            {HISTORY_TABLE_HEAD.map((head) => (
                                <th key={head} className="border-b bg-[#181c3a] p-2 text-gray-400">
                                    <Typography
                                        variant="small"
                                        color="inherit"
                                        className="text-xs leading-none font-medium"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    {(myBets !== undefined || myBets.length !== 0) &&
                        <tbody>
                            {myBets.map(({ id, updated_at, amount, cashout, coefficient_id, win, win_amount }, index) => {
                                const classes = "px-1 py-0 bg-[#181c3a] border-b-2 border-[#2e335c]";

                                return (
                                    <tr key={id} className="rounded-md text-gray-400" >
                                        <td className={classes}>
                                            <Typography variant="small" color="inherit" className="text-xs font-medium ">
                                                <Moment format="YYYY-MMM-DD h:mm a">
                                                    {updated_at}
                                                </Moment>
                                            </Typography>

                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                {coefficient_id}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                {(cashout !== null && cashout !== undefined) &&
                                                    formatCoefficient(cashout)
                                                }
                                                {(cashout == null && cashout !== undefined) &&
                                                    <>___</>
                                                }
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                {formatAmount(amount)}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                {win == true &&
                                                    <CheckCircleIcon className="h-6 w-6 text-[#8fc92c]" strokeWidth={2.0} />
                                                }
                                                {win == false &&
                                                    <XCircleIcon className="h-6 w-6 text-[#ff292c]" strokeWidth={2.0} />
                                                }
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography variant="small" color="inherit" className="text-xs font-medium">
                                                {(win_amount !== null && win_amount !== undefined) &&
                                                    formatAmount(win_amount)
                                                }
                                                {(win_amount == null && win_amount !== undefined) &&
                                                    <>___</>
                                                }
                                            </Typography>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    }
                </table>

                {(myBets === undefined || myBets.length == 0) &&
                    <div className="flex justify-center items-center h-full flex-col my-4">
                        <DocumentMagnifyingGlassIcon className="h-24 w-24 text-[#a3a4b0] col-span-full" strokeWidth={2.0} />
                        <span className="text-[#a3a4b0] col-span-full text-xl">You haven't placed any bets yet</span>
                    </div>
                }
            </Card>

        </div>
    )
}
