import airplaneSprites from '../../assets/images/airplaneSprites.png';
import styled, { keyframes } from 'styled-components';
const animation = keyframes`
  100% { background-position: -9600px; }
`;
export const Airplane = styled.div`
  height: 132px;
  width: 200px;
  position: absolute;
  bottom: -50px;
  left: -50px;
  transform: scale(0.4,0.4);
  background: url(${airplaneSprites}) left center;
  animation: ${animation} 5.0s steps(48) infinite; 

  @media (max-width: 768px) {
    bottom: -70px;
    left: -50px;
    transform: scale(0.3,0.3);
  }
`;