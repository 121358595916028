import { useRef, useState, useEffect } from "react";
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Context } from './Constants';
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Textarea, Typography } from "@material-tailwind/react";

import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';


import axios from 'axios';
import { toast } from "react-toastify";
import { setAccountBalance } from "../features/accountBalanceSlice";
import { setPhone } from "../features/phoneSlice";
axios.defaults.withCredentials = true;

const WITHDRAWAL_DETAILS_REGEX = /[\S\s]+[\S]+/;
const WITHDRAWAL_AMOUNT_REGEX = /(?!^$)([^\s])/;

const Withdraw = (props) => {
    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const dispatch = useDispatch();

    const accountBalance = useSelector((state) => state.accountBalance.value);
    const authentication = useSelector((state) => state.authentication.value);
    const previousGames = useSelector((state) => state.previousGames.value);
    const userId = useSelector((state) => state.userId.value);
    const myBets = useSelector((state) => state.myBets.value);
    const username = useSelector((state) => state.username.value);
    const phone = useSelector((state) => state.phone.value);
    const email = useSelector((state) => state.email.value);

    const minimumDeposit = JSON.parse(localStorage.getItem('minimumDeposit'));
    const maximumDeposit = JSON.parse(localStorage.getItem('maximumDeposit'));
    const marketerStatus = JSON.parse(localStorage.getItem('marketerStatus'));
    const directDeposit = JSON.parse(localStorage.getItem('directDeposit'));
    const directDepositInstructions = JSON.parse(localStorage.getItem('directDepositInstructions'));
    const withdrawalInstructions = JSON.parse(localStorage.getItem('withdrawalInstructions'));
    const mpesaPaymentInstructions = JSON.parse(localStorage.getItem('mpesaPaymentInstructions'));
    const gameCurrency = JSON.parse(localStorage.getItem('gameCurrency'));
    const directWithdrawal = JSON.parse(localStorage.getItem('directWithdrawal'));
    const directWithdrawalGateway = JSON.parse(localStorage.getItem('directWithdrawalGateway'));
    const minimumWithdrawal = JSON.parse(localStorage.getItem('minimumWithdrawal'));
    const maximumWithdrawal = JSON.parse(localStorage.getItem('maximumWithdrawal'));


    const withdrawalDetailsRef = useRef();
    const withdrawalAmountRef = useRef();
    const errRef = useRef();
    const phoneRef = useRef();

    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);


    const [errMsg, setErrMsg] = useState([]);
    const [phoneError, setPhoneError] = useState('');


    const [withdrawalDetails, setWithdrawalDetails] = useState('');
    const [validWithdrawalDetails, setValidWithdrawalDetails] = useState(false);
    const [WithdrawalDetailsFocus, setWithdrawalDetailsFocus] = useState(false);


    const [withdrawalAmount, setWithdrawalAmount] = useState('');
    const [validWithdrawalAmount, setValidWithdrawalAmount] = useState(false);
    const [WithdrawalAmountFocus, setWithdrawalAmountFocus] = useState(false);


    const [withdrawalProcessing, setWithdrawalProcessing] = useState(false);
    const [alternativeWithdrawal, setAlternativeWithdrawal] = useState(false);

    const countryCode = JSON.parse(localStorage.getItem('countryCode'));


    const formatAmount = (amount) => {
        return new Intl.NumberFormat("us-EN", {
            style: "currency",
            currency: gameCurrency,
            currencyDisplay: "narrowSymbol",
        }).format(amount);
    }



    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    const handleAlternativeWithdrawal = () => {
        if (alternativeWithdrawal === true) {
            setAlternativeWithdrawal(() => false);
        } else {
            setAlternativeWithdrawal(() => true);
        }
    }

    useEffect(() => {
        if (directWithdrawalGateway == 'dusupay_payment' && directWithdrawal == true) {

            if (phone.trim().length < 10) {
                setValidPhone(() => false);
            } else {
                setValidPhone(() => true);
            }
        }
    }, [phone,
        directWithdrawalGateway,
        directWithdrawal])

    useEffect(() => {
        if (directWithdrawal == false) {
            withdrawalDetailsRef.current.focus();
        }
    }, [])

    useEffect(() => {
        withdrawalAmountRef.current.focus();
    }, [])

    useEffect(() => {
        setValidWithdrawalDetails(WITHDRAWAL_DETAILS_REGEX.test(withdrawalDetails));

    }, [withdrawalDetails])

    useEffect(() => {
        if (
            withdrawalAmount >= Number(minimumWithdrawal) &&
            withdrawalAmount <= Number(maximumWithdrawal) &&
            withdrawalAmount <= Number(accountBalance)
        ) {
            setValidWithdrawalAmount(true);
        } else {
            setValidWithdrawalAmount(false);
        }
    }, [withdrawalAmount, minimumWithdrawal, maximumWithdrawal, setAccountBalance])


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setWithdrawalProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/recordWithdrawalRequest`,
                { userId: userId, details: withdrawalDetails, amount: withdrawalAmount },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            );
            dispatch(setAccountBalance(response.data.data.new_account_balance));
            setWithdrawalProcessing(() => false);
            props.handleOpenWithdraw();
            successNotify('Withdrawal request submitted successfully.');
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
                setErrMsg('No Server Response');
                errRef.current.scrollIntoView();
            } else {
                errorNotify('Withdrawal Failed');
                setErrMsg('Withdrawal Failed');
                errRef.current.scrollIntoView();
            }
            setWithdrawalProcessing(() => false);
        }
    }




    const handleDirectWithdrawalSubmit = async (e) => {
        try {
            e.preventDefault();
            setWithdrawalProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/directWithdrawalRequest`,
                { phone: phone, userId: userId, amount: withdrawalAmount },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                    },
                }
            )

            if (response?.data.errorCode != null) {
                errorNotify('Transaction Failed');
                setErrMsg(response?.data.errorMessage);
                errRef.current.scrollIntoView();
                setWithdrawalProcessing(() => false);
            } else {
                //  console.log(response);
                dispatch(setAccountBalance(response.data.data.new_account_balance));
                setWithdrawalProcessing(() => false);
                props.handleOpenWithdraw();
                successNotify('Withdrawal request initiated successfully.');
            }

        } catch (error) {
            setErrMsg(error.response.data.message);
            setWithdrawalProcessing(() => false);
            errorNotify(error.response.data.message);
            errRef.current.scrollIntoView();

        }

    }


    return (

        <div>
            {directWithdrawal == true &&
                <>
                    {/* <Button onClick={handleAlternativeWithdrawal} fullWidth={true} color="amber" className="my-2">
                    {alternativeWithdrawal === false &&
                        <>
                            Try an alternative withdrawal method
                        </>
                    }
                    {alternativeWithdrawal === true &&
                        <>
                            Try direct Withdrawal
                        </>
                    }
                </Button> */}
                </>
            }


            {(directWithdrawal == false || alternativeWithdrawal === true) &&
                <div className='prose text-gray-200 text-xs' dangerouslySetInnerHTML={{ __html: withdrawalInstructions }} />
            }


            {(directWithdrawal == false || alternativeWithdrawal === true) &&
                <Card className="mx-auto w-full max-w-[24rem] my-4">
                    <CardBody className="pt-0 flex flex-col gap-4">


                        <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                            {errMsg !== '' &&
                                <li>{errMsg}<br /></li>
                            }
                            {phoneError !== '' &&
                                <li>{phoneError}<br /></li>
                            }

                        </ul>



                        <Input
                            variant="outlined"
                            label="Amount"
                            size="lg"
                            type="number"
                            id="amount"
                            ref={withdrawalAmountRef}
                            autoComplete="off"
                            aria-invalid={validWithdrawalAmount ? "false" : "true"}
                            aria-describedby="withdrawalAmountNote"
                            onChange={(e) => setWithdrawalAmount(e.target.value)}
                            value={withdrawalAmount}
                            onFocus={() => setWithdrawalAmountFocus(true)}
                            onBlur={() => setWithdrawalAmountFocus(false)}
                            error={withdrawalAmount && !validWithdrawalAmount ? true : false}
                            success={validWithdrawalAmount ? true : false}
                            required
                        />
                        <Typography
                            id="withdrawalAmountNote"
                            variant="small"
                            color="red"
                            className={"mt-2 flex items-center gap-1 font-normal" + withdrawalAmountRef && withdrawalAmount && !validWithdrawalAmount ? "flex" : "hidden"}
                        >
                            Must be greater than {minimumWithdrawal}! <br />
                            Must be less than {maximumWithdrawal}! <br />
                            Must be less than account balance! <br />
                        </Typography>

                        <Textarea
                            variant="outlined"
                            label="Withdrawal Details"
                            size="lg"
                            type="text"
                            id="withdrawalDetails"
                            ref={withdrawalDetailsRef}
                            autoComplete="off"
                            aria-invalid={validWithdrawalDetails ? "false" : "true"}
                            aria-describedby="withdrawalDetailsNote"
                            onChange={(e) => setWithdrawalDetails(e.target.value)}
                            value={withdrawalDetails}
                            onFocus={() => setWithdrawalDetailsFocus(true)}
                            onBlur={() => setWithdrawalDetailsFocus(false)}
                            error={withdrawalDetails && !validWithdrawalDetails ? true : false}
                            success={validWithdrawalDetails ? true : false}
                            required
                        />

                        <Typography
                            id="withdrawalDetailsNote"
                            variant="small"
                            color="red"
                            className={"mt-2 flex items-center gap-1 font-normal" + withdrawalDetailsRef && withdrawalDetails && !validWithdrawalDetails ? "flex" : "hidden"}
                        >
                            Cannot be empty! <br />
                            Must be more than one character!
                        </Typography>



                    </CardBody>
                    <CardFooter className="pt-0">
                        {withdrawalProcessing === true &&
                            <Button onClick={handleSubmit} disabled={!validWithdrawalDetails || !validWithdrawalAmount ? true : false} color='indigo' variant="gradient" fullWidth>
                                <span className="flex items-center justify-center gap-2 mx-auto">
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Processing...
                                </span>
                            </Button>
                        }
                        {withdrawalProcessing === false &&
                            <Button onClick={handleSubmit} disabled={!validWithdrawalDetails || !validWithdrawalAmount ? true : false} color='indigo' variant="gradient" fullWidth>
                                Submit
                            </Button>
                        }
                    </CardFooter>
                </Card>
            }

            {directWithdrawal == true && alternativeWithdrawal === false &&
                <Card className="mx-auto w-full max-w-[24rem] my-4">
                    <CardBody className="pt-0 flex flex-col gap-4">


                        <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                            {errMsg !== '' &&
                                <li>{errMsg}<br /></li>
                            }

                        </ul>


                        {directWithdrawalGateway == 'dusupay_payment' && directWithdrawal == true &&
                            <>
                                <PhoneInput
                                    variant="outlined"
                                    label="Phone"
                                    size="lg"
                                    id="phone"
                                    country={countryCode}
                                    enableSearch={true}
                                    value={phone}
                                    onChange={e => dispatch(setPhone(e))}
                                    ref={phoneRef}
                                    autoComplete="off"
                                    required
                                    aria-invalid={validPhone ? "false" : "true"}
                                    aria-describedby="eidPhone"
                                    onFocus={() => setPhoneFocus(true)}
                                    onBlur={() => setPhoneFocus(false)}
                                    error={phone && !validPhone ? true : false}
                                    success={validPhone ? true : false}

                                />

                                <Typography
                                    id="eidPhone"
                                    variant="small"
                                    color="red"
                                    className={"mt-2 flex items-center gap-1 font-normal " + phoneFocus && phone && !validPhone ? "flex" : "hidden"}
                                >
                                    Must be a valid phone number.
                                </Typography>

                            </>
                        }


                        <Input
                            variant="outlined"
                            label="Amount"
                            size="lg"
                            type="number"
                            id="amount"
                            ref={withdrawalAmountRef}
                            autoComplete="off"
                            aria-invalid={validWithdrawalAmount ? "false" : "true"}
                            aria-describedby="withdrawalAmountNote"
                            onChange={(e) => setWithdrawalAmount(e.target.value)}
                            value={withdrawalAmount}
                            onFocus={() => setWithdrawalAmountFocus(true)}
                            onBlur={() => setWithdrawalAmountFocus(false)}
                            error={withdrawalAmount && !validWithdrawalAmount ? true : false}
                            success={validWithdrawalAmount ? true : false}
                            required
                        />

                        <Typography
                            id="withdrawalAmountNote"
                            variant="small"
                            color="red"
                            className={"mt-2 flex items-center gap-1 font-normal" + withdrawalAmountRef && withdrawalAmount && !validWithdrawalAmount ? "flex" : "hidden"}
                        >
                            Must be greater than {minimumWithdrawal}! <br />
                            Must be less than {maximumWithdrawal}! <br />
                            Must be less than account balance! <br />
                        </Typography>



                    </CardBody>
                    <CardFooter className="pt-0">
                        {withdrawalProcessing === true &&
                            <Button onClick={handleSubmit} disabled={!validWithdrawalDetails || !validWithdrawalAmount ? true : false} color='indigo' variant="gradient" fullWidth>
                                <span className="flex items-center justify-center gap-2 mx-auto">
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Processing...
                                </span>
                            </Button>
                        }
                        {withdrawalProcessing === false &&
                            <Button onClick={handleDirectWithdrawalSubmit} disabled={!validWithdrawalAmount ? true : false} color='indigo' variant="gradient" fullWidth>
                                Submit
                            </Button>
                        }
                    </CardFooter>
                </Card>
            }
            <div className="grid grid-cols-2 p-2 gap-2 text-sm w-full text-orange-200 border rounded">
                <span className="w-full">
                    Minimum Withdrawal: <b>{formatAmount(minimumWithdrawal)}</b>
                </span>
                <span className="w-full">
                    Maximum Withdrawal: <b>{formatAmount(maximumWithdrawal)}</b>
                </span>
            </div>

        </div>
    )
}

export default Withdraw