import { createSlice } from '@reduxjs/toolkit'

export const accountBalanceSlice = createSlice({
    name: 'accountBalance',
    initialState: {
      value: 500000,
    },
    reducers: {
      setAccountBalance: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setAccountBalance } = accountBalanceSlice.actions;

export default accountBalanceSlice.reducer;
