import { createSlice } from '@reduxjs/toolkit'

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
      value: false,
    },
    reducers: {
        setAuthentication: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setAuthentication } = authenticationSlice.actions;

export default authenticationSlice.reducer;
