import { createSlice } from '@reduxjs/toolkit'

export const previousGamesSlice = createSlice({
    name: 'previousGames',
    initialState: {
      value: [],
    },
    reducers: {
      setPreviousGames: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setPreviousGames } = previousGamesSlice.actions;

export default previousGamesSlice.reducer;
