import { useRef, useState, useEffect } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Typography } from "@material-tailwind/react";
import axios from 'axios';
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';


import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';


axios.defaults.withCredentials = true;



const StripePaymentStatus = (props) => {
    const userId = useSelector((state) => state.userId.value);
    const myBets = useSelector((state) => state.myBets.value);
    const dispatch = useDispatch();

    const clientSiteUrl = JSON.parse(localStorage.getItem('clientSiteUrl'));


    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);

    const stripe = useStripe();
    const [message, setMessage] = useState(null);

    const [stripeFormProcessing, setStripeFormProcessing] = useState(false);


    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    useEffect(() => {
        if (!stripe) {
            return;
        }

        // Retrieve the "payment_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        );

        const paymentIntent = new URLSearchParams(window.location.search).get(
            'payment_intent'
        );

        // Retrieve the PaymentIntent
        stripe
            .retrievePaymentIntent(clientSecret)
            .then(({ paymentIntent }) => {
                // Inspect the PaymentIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (paymentIntent.status) {
                    case 'succeeded':
                        setMessage('Success! Payment received.');

                        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                        const response = axios.post(`${baseUrl}/api/successStripePayment`,
                            { userId: userId, payment_intent_client_secret: clientSecret, payment_intent: paymentIntent },
                            {
                                headers: {
                                    'Accept': 'application/vnd.api+json',
                                    'Content-Type': 'application/vnd.api+json',
                                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                                },
                            }
                        ).then((response) => {
                            successNotify('Success! Payment received.');
                            window.location.replace(clientSiteUrl);
                        }).catch((err) => {
                            if (!err?.response) {
                                errorNotify('No Server Response');
                            } else {
                                errorNotify(err.response.data.message);
                            }
                        });

                        break;

                    case 'processing':
                        setMessage("Payment processing. We'll update you when payment is received.");


                        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                        axios.post(`${baseUrl}/api/processingStripePayment`,
                            { payment_intent_client_secret: clientSecret, payment_intent: paymentIntent },
                            {
                                headers: {
                                    'Accept': 'application/vnd.api+json',
                                    'Content-Type': 'application/vnd.api+json',
                                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                                },
                            }
                        ).then((response) => {
                            infoNotify("Payment processing. Please reload the page after sometime to process.");

                        }).catch((err) => {

                            if (!err?.response) {
                                errorNotify('No Server Response');
                            } else {
                                errorNotify(err.response.data.message);
                            }
                        });

                        break;

                    case 'requires_payment_method':
                        // Redirect your user back to your payment page to attempt collecting
                        // payment again
                        setMessage('Payment failed. Please try another payment method.');


                        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                        axios.post(`${baseUrl}/api/failedStripePayment`,
                            { payment_intent_client_secret: clientSecret, payment_intent: paymentIntent },
                            {
                                headers: {
                                    'Accept': 'application/vnd.api+json',
                                    'Content-Type': 'application/vnd.api+json',
                                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                                },
                            }
                        ).then((response) => {
                            warnNotify('Payment failed. Please try another payment method.');
                            window.location.replace(clientSiteUrl);

                        }).catch((err) => {
                            if (!err?.response) {
                                errorNotify('No Server Response');
                            } else {
                                errorNotify(err.response.data.message);
                            }
                        });

                        break;

                    default:
                        setMessage('Something went wrong.');

                        axios.get(`${baseUrl}/sanctum/csrf-cookie`);
                        axios.post(`${baseUrl}/api/failedStripePayment`,
                            { payment_intent_client_secret: clientSecret, payment_intent: paymentIntent },
                            {
                                headers: {
                                    'Accept': 'application/vnd.api+json',
                                    'Content-Type': 'application/vnd.api+json',
                                    'Authorization': `Bearer ${localStorage.getItem('crashToken')}`
                                },
                            }
                        ).then((response) => {
                            errorNotify('Something went wrong.');
                            window.location.replace(clientSiteUrl);
                        }).catch((err) => {
                            if (!err?.response) {
                                errorNotify('No Server Response');
                            } else {
                                errorNotify(err.response.data.message);
                            }
                        });

                        break;
                }
            });
        props.setStripeProcessPayment(false);



    }, [stripe, props, message]);



}


export default StripePaymentStatus