import { createSlice } from '@reduxjs/toolkit'

export const statisticsResultsSlice = createSlice({
    name: 'statisticsResults',
    initialState: {
      value: [],
    },
    reducers: {
        setStatisticsResults: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setStatisticsResults } = statisticsResultsSlice.actions;

export default statisticsResultsSlice.reducer;
