import { createSlice } from '@reduxjs/toolkit'

export const encryptionKeySlice = createSlice({
    name: 'encryptionKey',
    initialState: {
      value: '',
    },
    reducers: {
        setEncryptionKey: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setEncryptionKey } = encryptionKeySlice.actions;

export default encryptionKeySlice.reducer;
