import { createSlice } from '@reduxjs/toolkit'

export const myBetsSlice = createSlice({
    name: 'myBets',
    initialState: {
        value: [],
    },
    reducers: {
        setMyBets: (state, action) => {
            state.value = action.payload
        },
        addMyBets: (state, action) => {
            state.value = [action.payload, ...state.value]
        },
    },
})

// Action creators are generated for each case reducer function
export const { setMyBets, addMyBets } = myBetsSlice.actions;

export default myBetsSlice.reducer;
