import { createSlice } from '@reduxjs/toolkit'

export const userIdSlice = createSlice({
    name: 'userId',
    initialState: {
      value: 9999,
    },
    reducers: {
        setUserId: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setUserId } = userIdSlice.actions;

export default userIdSlice.reducer;
