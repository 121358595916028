import { createSlice } from '@reduxjs/toolkit'

export const openDusupayPaymentSlice = createSlice({
    name: 'openDusupayPayment',
    initialState: {
      value: false,
    },
    reducers: {
        setOpenDusupayPayment: (state, action) => {
        state.value = action.payload
      },
    },
})
 
// Action creators are generated for each case reducer function
export const { setOpenDusupayPayment } = openDusupayPaymentSlice.actions;

export default openDusupayPaymentSlice.reducer;


