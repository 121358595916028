import { createSlice } from '@reduxjs/toolkit'

export const liveBetsSlice = createSlice({
    name: 'liveBets',
    initialState: {
      value: [],
    },
    reducers: {
        setLiveBets: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setLiveBets } = liveBetsSlice.actions;

export default liveBetsSlice.reducer;
