import { createSlice } from '@reduxjs/toolkit'

export const musicSlice = createSlice({
    name: 'music',
    initialState: {
      value: false,
    },
    reducers: {
        setMusic: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setMusic } = musicSlice.actions;

export default musicSlice.reducer;
