import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { Button, Card, CardBody, CardFooter, CardHeader, Input, Typography } from "@material-tailwind/react";

import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

import axios from 'axios';
import { setName } from "../features/nameSlice";
import { setUsername } from "../features/usernameSlice";
import { setPhone } from "../features/phoneSlice";
import { toast } from "react-toastify";
import { setEmail } from "../features/emailSlice";
axios.defaults.withCredentials = true;

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Register = (props) => {

    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const dispatch = useDispatch();

    const email = useSelector((state) => state.email.value);
    const userId = useSelector((state) => state.userId.value);
    const phone = useSelector((state) => state.phone.value);
    const username = useSelector((state) => state.username.value);
    const name = useSelector((state) => state.name.value);
  

    const registrationType = JSON.parse(localStorage.getItem('registrationType'));


    const emailRef = useRef();
    const usernameRef = useRef();
    const nameRef = useRef();
    const phoneRef = useRef();
    const errRef = useRef();


    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [validUsername, setValidUsername] = useState(false);
    const [usernameFocus, setUsernameFocus] = useState(false);

    const [validName, setValidName] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);

    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);


    const [errMsg, setErrMsg] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [profileProcessing, setProfileProcessing] = useState(false);

    const countryCode = JSON.parse(localStorage.getItem('countryCode'));

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        usernameRef.current.focus();
    }, [])

    useEffect(() => {
        nameRef.current.focus();
    }, [])

    // useEffect(() => {
    //     phoneRef.current.focus();
    // }, [])

    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        if (username.trim().length < 3) {
            setValidUsername(() => false);
        } else {
            setValidUsername(() => true);
        }
    }, [username])


    useEffect(() => {
        if (name !== null) {
            if (name.trim().length < 3) {
                setValidName(() => false);
            } else {
                setValidName(() => true);
            }
        }
    }, [name])

    useEffect(() => {
        if (phone !== null) {
            if (phone.trim().length < 9) {
                setValidPhone(() => false);
            } else {
                setValidPhone(() => true);
            }
        }
    }, [phone])



    useEffect(() => {
        setErrMsg('');
    }, [email, username, name, phone])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setProfileProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);
            const response = await axios.post(`${baseUrl}/api/updateProfile/`,
                { userId: userId, name: name, email: email, username: username, phone: phone },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json'
                    },
                }
            );

            dispatch(setUsername(response?.data.data.user.username));
            dispatch(setName(response?.data.data.user.name));
            dispatch(setPhone(response?.data.data.user.phone));
            setProfileProcessing(() => false);
            props.handleOpenProfile();
            successNotify('Your profile has been update successfully.');
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
                setErrMsg('No Server Response');
                errRef.current.scrollIntoView();
            } else {
                errorNotify('Profile update Failed');
                setErrMsg(err.response.data.message);
                errRef.current.scrollIntoView();
            }
            setProfileProcessing(() => false);
        }

    }

    return (

        <div>
            <Card className="mx-auto w-full max-w-[24rem] my-10">
                <CardHeader
                    variant="gradient"
                    color="indigo"
                    className="mb-4 grid h-20 place-items-center"
                >
                    <Typography variant="h3" color="white">
                        Profile
                    </Typography>
                </CardHeader>
                <CardBody className="pt-0 flex flex-col gap-4">

                    <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                        {errMsg !== '' &&
                            <li>{errMsg}<br /></li>
                        }
                        {usernameError !== '' &&
                            <li>{usernameError}<br /></li>
                        }
                        {nameError !== '' &&
                            <li>{nameError}</li>
                        }
                        {phoneError !== '' &&
                            <li>{phoneError}</li>
                        }
                        {emailError !== '' &&
                            <li>{phoneError}</li>
                        }
                    </ul>

                    <PhoneInput
                        variant="outlined"
                        label="Phone"
                        size="lg"
                        id="phone"
                        country={countryCode}
                        enableSearch={true}
                        value={phone}
                        onChange={e => dispatch(setPhone(e))}
                        ref={phoneRef}
                        autoComplete="off"
                        required
                        aria-invalid={validPhone ? "false" : "true"}
                        aria-describedby="eidPhone"
                        onFocus={() => setPhoneFocus(true)}
                        onBlur={() => setPhoneFocus(false)}
                        error={phone && !validPhone ? true : false}
                        success={validPhone ? true : false}
                        disabled={(registrationType === 'phone_number_only') ? true : false}

                    />

                    <Typography
                        id="eidPhone"
                        variant="small"
                        color="red"
                        className={"mt-2 flex items-center gap-1 font-normal " + phoneFocus && phone && !validPhone ? "flex" : "hidden"}
                    >
                        Must be a valid phone number.
                    </Typography>

                    <Input
                        variant="outlined"
                        label="Email"
                        size="lg"
                        type="email"
                        id="email"
                        ref={emailRef}
                        autoComplete="off"
                        onChange={(e) => dispatch(setEmail(e.target.value))}
                        value={email}
                        required
                        aria-invalid={validEmail ? "false" : "true"}
                        aria-describedby="eidnote"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        error={email && !validEmail ? true : false}
                        success={validEmail ? true : false}
                        disabled={(registrationType === 'email_and_username') ? true : false}

                    />

                    <Typography
                        id="eidnote"
                        variant="small"
                        color="red"
                        className={"mt-2 flex items-center gap-1 font-normal " + emailFocus && email && !validEmail ? "flex" : "hidden"}
                    >
                        Must be a valid email address.
                    </Typography>



                    <Input
                        variant="outlined"
                        label="Name"
                        size="lg"
                        type="text"
                        id="name"
                        ref={nameRef}
                        autoComplete="off"
                        onChange={(e) => dispatch(setName(e.target.value))}
                        value={name}
                        required
                        aria-invalid={validName ? "false" : "true"}
                        aria-describedby="namenote"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                        error={name && !validName ? true : false}
                        success={validName ? true : false}
                    />
                    <Typography
                        id="namenote"
                        variant="small"
                        color="red"
                        className={"mt-2 flex items-center gap-1 font-normal " + nameFocus && name && !validName ? "flex" : "hidden"}
                    >
                        Minimum of 3 characters.<br />
                    </Typography>



                    <Input
                        variant="outlined"
                        label="Username"
                        size="lg"
                        type="text"
                        id="username"
                        ref={usernameRef}
                        autoComplete="off"
                        onChange={(e) => dispatch(setUsername(e.target.value))}
                        value={username}
                        required
                        aria-invalid={validUsername ? "false" : "true"}
                        aria-describedby="uidnote"
                        onFocus={() => setUsernameFocus(true)}
                        onBlur={() => setUsernameFocus(false)}
                        error={username && !validUsername ? true : false}
                        success={validUsername ? true : false}
                    />
                    <Typography
                        id="uidnote"
                        variant="small"
                        color="red"
                        className={"mt-2 flex items-center gap-1 font-normal " + usernameFocus && username && !validUsername ? "flex" : "hidden"}
                    >
                        Minimum of 3 characters.<br />
                    </Typography>





                </CardBody>
                <CardFooter className="pt-0">
                    {profileProcessing === true &&
                        <Button onClick={handleSubmit} disabled={!validEmail || !validName || !validPhone || !validUsername || profileProcessing ? true : false} color='indigo' variant="gradient" fullWidth>
                            <span className="flex items-center justify-center gap-2 mx-auto">
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </span>
                        </Button>
                    }
                    {profileProcessing === false &&
                        <Button onClick={handleSubmit} disabled={!validEmail || !validName || !validPhone || !validUsername ? true : false} color='indigo' variant="gradient" fullWidth>
                            Update Profile
                        </Button>
                    }

                </CardFooter>
            </Card>

        </div>
    )
}

export default Register