import { createSlice } from '@reduxjs/toolkit'

export const gameModeSlice = createSlice({
    name: 'gameMode',
    initialState: {
      value: 'slow',
    },
    reducers: {
        setGameMode: (state, action) => {
        state.value = action.payload
      },
    },
})

// Action creators are generated for each case reducer function
export const { setGameMode } = gameModeSlice.actions;

export default gameModeSlice.reducer;
