import { useRef, useState, useEffect } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Typography } from "@material-tailwind/react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useSelector, useDispatch } from 'react-redux';

import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

import axios from 'axios';
import { setPhone } from "../features/phoneSlice";
import { setEmail } from "../features/emailSlice";
import { setUserId } from "../features/userIdSlice";
import { setMyBets } from "../features/myBetsSlice";
import { setUsername } from "../features/usernameSlice";
import { setAccountBalance } from "../features/accountBalanceSlice";
import { setAuthentication } from "../features/authenticationSlice";
import { toast } from "react-toastify";
axios.defaults.withCredentials = true;

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Login = (props) => {
    const emailRef = useRef();
    const phoneRef = useRef();
    const errRef = useRef();

    const [baseUrl, setBaseUrl] = useState(window.DASHBOARD_URL);
    const dispatch = useDispatch();
  
    const email = useSelector((state) => state.email.value);
    const phone = useSelector((state) => state.phone.value);
  

    const registrationType = JSON.parse(localStorage.getItem('registrationType'));




    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [errMsg, setErrMsg] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [processing, setProcessing] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);

    const countryCode = JSON.parse(localStorage.getItem('countryCode'));


    useEffect(() => {
        if (registrationType === 'email_and_username') {
            emailRef.current.focus();
        }
    }, [])

    // useEffect(() => {
    //     if (registrationType === 'phone_number_only') {
    //         phoneRef.current.focus();
    //     }
    // }, [])
    const warnNotify = (message) => {
        toast.warn(message, {
        });
    }
    const successNotify = (message) => {
        toast.success(message, {
        });
    }
    const infoNotify = (message) => {
        toast.info(message, {
        });
    }
    const errorNotify = (message) => {
        toast.error(message, {
        });
    }

    useEffect(() => {
        if (registrationType === 'email_and_username') {
            setValidEmail(EMAIL_REGEX.test(email));
        }
    }, [email])


    useEffect(() => {
        if (registrationType === 'phone_number_only') {

            if (phone.trim().slice(0, 3) == '254') {
                if (phone.trim().length !== 12) {
                    setValidPhone(() => false);
                } else {
                    setValidPhone(() => true);
                }
            } else {
                if (phone.trim().length < 10) {
                    setValidPhone(() => false);
                } else {
                    setValidPhone(() => true);
                }
            }
        }
    }, [phone])

    useEffect(() => {
        if (password.trim().length < 4) {
            setValidPassword(() => false);
        } else {
            setValidPassword(() => true);
        }
    }, [password])



    useEffect(() => {
        setErrMsg('');
    }, [email, phone, password])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setProcessing(() => true);
            await axios.get(`${baseUrl}/sanctum/csrf-cookie`);

            const response = await axios.post(`${baseUrl}/api/login/`,
                { email: email, phone: phone, password },
                {
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json'
                    },
                }
            );
            dispatch(setPhone(''));
            dispatch(setEmail(''));
            setPassword('');
            dispatch(setUserId(response?.data.data.user.id));
            dispatch(setMyBets(response?.data.data.myBets));
            dispatch(setEmail(response?.data.data.user.email));
            dispatch(setPhone(response?.data.data.user.phone));
            dispatch(setUsername(response?.data.data.user.username));
            dispatch(setAccountBalance(response?.data.data.user.wallet));

            localStorage.setItem('crashToken', response?.data.data.token);
            dispatch(setAuthentication(true));

            props.handleOpenAuth();
            successNotify('You are now logged in.');
            setProcessing(() => false);
        } catch (err) {
            if (!err?.response) {
                errorNotify('No Server Response');
                setErrMsg('No Server Response');
                errRef.current.scrollIntoView();
            } else {
                errorNotify(err.response.data.message);
                setErrMsg('Login Failed');
                errRef.current.scrollIntoView();
                setEmailError(() => err.response.data.message);
            }
            // errRef.current.focus();
            setProcessing(() => false);
        }

    }

    return (

        <div>
            <Card className="mx-auto w-full max-w-[24rem] my-10">
                <CardHeader
                    variant="gradient"
                    color="indigo"
                    className="mb-4 grid h-20 place-items-center"
                >
                    <Typography variant="h3" color="white">
                        Login
                    </Typography>
                </CardHeader>
                <CardBody className="pt-0 flex flex-col gap-4">

                    <ul className="text-red-600 font-normal text-sm" ref={errRef} aria-live="assertive">
                        {errMsg !== '' &&
                            <li>{errMsg}<br /></li>
                        }
                        {emailError !== '' &&
                            <li>{emailError}<br /></li>
                        }
                        {phoneError !== '' &&
                            <li>{phoneError}<br /></li>
                        }
                        {passwordError !== '' &&
                            <li>{passwordError}</li>
                        }
                    </ul>


                    {registrationType === 'phone_number_only' &&
                        <>
                            <PhoneInput
                                variant="outlined"
                                label="Phone"
                                size="lg"
                                id="phone"
                                country={countryCode}
                                enableSearch={true}
                                value={phone}
                                onChange={e =>dispatch(setPhone(e))}
                                ref={phoneRef}
                                autoComplete="off"
                                required
                                aria-invalid={validPhone ? "false" : "true"}
                                aria-describedby="eidPhone"
                                onFocus={() => setPhoneFocus(true)}
                                onBlur={() => setPhoneFocus(false)}
                                error={phone && !validPhone ? true : false}
                                success={validPhone ? true : false}

                            />

                            <Typography
                                id="eidPhone"
                                variant="small"
                                color="red"
                                className={"mt-2 flex items-center gap-1 font-normal " + phoneFocus && phone && !validPhone ? "flex" : "hidden"}
                            >
                                Must be a valid phone number.
                            </Typography>

                        </>
                    }


                    {registrationType === 'email_and_username' &&
                        <>
                            <Input
                                variant="outlined"
                                label="Email"
                                size="lg"
                                type="email"
                                id="email"
                                ref={emailRef}
                                autoComplete="off"
                                onChange={(e) => dispatch(setEmail(e.target.value))}
                                value={email}
                                required
                                aria-invalid={validEmail ? "false" : "true"}
                                aria-describedby="eidnote"
                                onFocus={() => setEmailFocus(true)}
                                onBlur={() => setEmailFocus(false)}
                                error={email && !validEmail ? true : false}
                                success={validEmail ? true : false}
                            />

                            <Typography
                                id="eidnote"
                                variant="small"
                                color="red"
                                className={"mt-2 flex items-center gap-1 font-normal " + emailFocus && email && !validEmail ? "flex" : "hidden"}
                            >
                                Must be a valid email address.
                            </Typography>
                        </>
                    }



                    <div className="relative flex w-full max-w-[24rem]">
                        <Input
                            variant="outlined"
                            label="Password"
                            size="lg"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            aria-invalid={validPassword ? "false" : "true"}
                            aria-describedby="passwordnote"
                            onFocus={() => setPasswordFocus(true)}
                            onBlur={() => setPasswordFocus(false)}
                            error={password && !validPassword ? true : false}
                            success={validPassword ? true : false}
                        />
                        <Button
                            size="sm"
                            variant="text"
                            onClick={handleShowPassword}
                            className="!absolute right-1 top-1 rounded"
                        >
                            {!showPassword &&
                                <EyeIcon className="h-6 w-6 text-gray-700" />
                            }
                            {showPassword &&
                                <EyeSlashIcon className="h-6 w-6 text-gray-700" />
                            }
                        </Button>
                    </div>
                    <Typography
                        id="passwordnote"
                        variant="small"
                        color="red"
                        className={"mt-2 flex items-center gap-1 font-normal " + password && passwordFocus && !validPassword ? "flex" : "hidden"}
                    >
                        Minimum of 4 characters.<br />
                    </Typography>

                </CardBody>
                <CardFooter className="pt-0">
                    {processing === true &&
                        <Button onClick={handleSubmit} disabled={true} color='indigo' variant="gradient" fullWidth>
                            <span className="flex items-center justify-center gap-2 mx-auto">
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </span>
                        </Button>
                    }
                    {processing === false &&
                        <>
                            {registrationType === 'email_and_username' &&
                                <Button onClick={handleSubmit} disabled={!validEmail || !validPassword ? true : false} color='indigo' variant="gradient" fullWidth>
                                    Login
                                </Button>
                            }
                            {registrationType === 'phone_number_only' &&
                                <Button onClick={handleSubmit} disabled={!validPhone || !validPassword ? true : false} color='indigo' variant="gradient" fullWidth>
                                    Login
                                </Button>
                            }
                        </>

                    }
                    <Typography className="font-normal">
                        Not yet registered?
                        <Button
                            variant="text"
                            onClick={props.handleOpenLogin}
                            className="p-2 text-xs"
                        >
                            <span>Sign Up</span>
                        </Button>
                    </Typography>

                    <Typography className="font-normal">
                        Forgot your password?
                        <Button
                            variant="text"
                            onClick={props.switchToPasswordReset}
                            className="p-2 text-xs"
                        >
                            <span>Reset</span>
                        </Button>
                    </Typography>

                </CardFooter>
            </Card>

        </div>
    )
}

export default Login